<section>
    <img class="logo" src="../../../assets/img/landing-page/DABubble-logo.png" alt="daBubble-logo">

    <div class="privacy-section">
        <a (click)="goBack()"><img class="arrow-back cursor-pointer" [src]="imgSrcArrow"
                (mouseover)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back-hover.png'"
                (mouseout)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back.png'" alt="arrow-back"></a>

        <span class="privacy-content">
            <h1 style="margin-bottom: 30px;" class="fs-46 pPHeadline">Datenschutzerklärung</h1>
            <p class="fs-18">Willkommen bei DABubble. Der Schutz Ihrer persönlichen Daten ist uns sehr wichtig. In
                dieser Datenschutzerklärung informieren wir Sie darüber, welche Daten wir erheben, wie wir sie verwenden
                und welche Rechte Sie bezüglich Ihrer Daten haben.</p>

            <h1 class="fs-25 pb-30">1. Verantwortliche Personen</h1>
            <p class="fs-18">Die Verantwortlichen für die Datenverarbeitung in Zusammenhang mit der Nutzung von DABubble
                sind:</p>
            <ul class="fs-18">
                <li>Márk Hegedüs</li>
                <li>Joost Heidrich</li>
                <li>Gábor Holzleiter</li>
            </ul>

            <h1 class="fs-25 pb-30">2. Erhobene Daten</h1>
            <p class="fs-18">Bei der Registrierung erheben wir folgende Daten:</p>
            <ul class="fs-18">
                <li>E-Mail-Adresse</li>
                <li>Name</li>
                <li>Passwort</li>
            </ul>

            <h1 class="fs-25 pb-30">3. Verwendung der Daten</h1>
            <p class="fs-18">Die erhobenen Daten werden ausschließlich für folgende Zwecke verwendet:</p>
            <ul class="fs-18">
                <li>Bereitstellung der Chat-Anwendung</li>
                <li>Verwaltung Ihres Benutzerkontos</li>
                <li>Gewährleistung der Sicherheit und Funktionalität der Anwendung</li>
            </ul>

            <h1 class="fs-25 pb-30">4. Weitergabe der Daten</h1>
            <p class="fs-18">Ihre Daten werden nicht an Dritte weitergegeben, es sei denn, dies ist zur Erfüllung
                unserer vertraglichen Verpflichtungen notwendig oder gesetzlich vorgeschrieben.</p>

            <h1 class="fs-25 pb-30">5. Ihre Rechte</h1>
            <p class="fs-18">Sie haben das Recht, jederzeit Auskunft über die bei uns gespeicherten personenbezogenen
                Daten zu erhalten. Außerdem haben Sie das Recht auf Berichtigung, Löschung und Einschränkung der
                Verarbeitung Ihrer Daten sowie das Recht auf Datenübertragbarkeit.</p>

            <h1 class="fs-25 pb-30">6. Kontakt</h1>
            <p class="fs-18">Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei
                Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen
                wenden Sie sich bitte an:</p>
            <ul class="fs-18">
                <li>Márk Hegedüs</li>
                <li>Joost Heidrich</li>
                <li>Gábor Holzleiter</li>
            </ul>

            <h1 class="fs-25 pb-30">7. Änderungen dieser Datenschutzerklärung</h1>
            <p class="fs-18">Wir behalten uns vor, diese Datenschutzerklärung jederzeit zu ändern, um sie an geänderte
                Rechtslagen oder Änderungen des Dienstes sowie der Datenverarbeitung anzupassen.</p>
        </span>
    </div>
</section>