<section>

    <img class="logo" src="../../../assets/img/landing-page/DABubble-logo.png" alt="daBubble-logo">

    <form [formGroup]="form" class="reset-password-section" (ngSubmit)="onSubmit()">
        <a href="/"><img class="arrow-back cursor-pointer" [src]="imgSrcArrow"
                (mouseover)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back-hover.png'"
                (mouseout)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back.png'" alt="arrow-back"></a>

        <h1 class="fs-46">Passwort zurücksetzen</h1>

        <div class="input-container" style="position: relative;">
            <img class="input-icon-lock" src="../../../assets/img/landing-page/lock.png" alt="lock">
            <input formControlName="newPassword" minlength="6" maxlength="21" required class="input-design"
                placeholder="Neues Passwort" type="{{revealPasswordService.type}}">
            <img (click)="revealPasswordService.togglePassword()" class="input-icon-reveal"
                src="{{revealPasswordService.imgUrl}}" alt="">
            <span
                *ngIf="form.get('newPassword')?.invalid && (form.get('newPassword')?.dirty || form.get('newPassword')?.touched)"
                class="error-message">
                Das Passwort muss mindestens 6 Zeichen lang sein.
            </span>
        </div>

        <div class="input-container" style="position: relative;">
            <img class="input-icon-lock" src="../../../assets/img/landing-page/lock.png" alt="lock">
            <input formControlName="confirmPassword" minlength="6" maxlength="21" required class="input-design"
                placeholder="Neues Kennwort bestätigen" type="{{revealPasswordService.type}}">
            <img (click)="revealPasswordService.togglePassword()" class="input-icon-reveal"
                src="{{revealPasswordService.imgUrl}}" alt="">
            <span
                *ngIf="form.hasError('passwordsMismatch') && (form.get('confirmPassword')?.dirty || form.get('confirmPassword')?.touched)"
                class="error-message">
                Die Passwörter stimmen nicht überein.
            </span>
        </div>

        <span class="btn-container">
            <button [ngClass]="{'blue-btn': form.valid, 'disabled-btn fs-18': !form.valid}" type="submit"
                [disabled]="!form.valid">
                Passwort ändern
            </button>
        </span>
    </form>

    <div class="legal-section">
        <a class="fs-18 purp-link" href="impressum">Impressum</a>
        <a class="fs-18 purp-link" href="privacy">Datenschutz</a>
    </div>

    <div class="message-container" [ngClass]="{'bg-white': succes}">
        <div class="email-sent-message" [ngClass]="{'animation-class': succes}">
            <img src="../../../assets/img/landing-page/send.png" alt="send">
            <p class="fs-36">Anmelden</p>
        </div>
    </div>
</section>