<div class="contentContainer" id="messageContainer">
  <h1 class="fs-32 title-mobile">Kanale:</h1>
  <div *ngFor="let element of allChannelSorted; let i = index">
    <div class="channelContainer cursor-pointer">
      <div
        *ngIf="
          element.uids.includes(currentUserId) ||
          element.id == 'wXzgNEb34DReQq3fEsAo7VTcXXNA'
        "
        class="channel"
        (click)="setChannel(element.id, 'channel', i, $event)"
      >
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <mask
        id="mask0_364_2314"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="31"
      >
        <rect y="0.5" width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_364_2314)">
        <path
          d="M9.0625 25.5C8.66666 25.5 8.34375 25.3438 8.09375 25.0312C7.84375 24.7188 7.77083 24.375 7.875 24L8.75 20.5H5.96875C5.55208 20.5 5.21875 20.3387 4.96875 20.0162C4.71875 19.6929 4.64583 19.3333 4.75 18.9375C4.8125 18.6458 4.95833 18.4167 5.1875 18.25C5.41666 18.0833 5.67708 18 5.96875 18H9.375L10.625 13H7.21875C6.80208 13 6.46875 12.8387 6.21875 12.5162C5.96875 12.1929 5.89583 11.8333 6 11.4375C6.0625 11.1458 6.20833 10.9167 6.4375 10.75C6.66666 10.5833 6.92708 10.5 7.21875 10.5H11.25L12.2812 6.40625C12.3437 6.13542 12.4792 5.91667 12.6875 5.75C12.8958 5.58333 13.1458 5.5 13.4375 5.5C13.8333 5.5 14.1562 5.65625 14.4062 5.96875C14.6562 6.28125 14.7292 6.625 14.625 7L13.75 10.5H18.75L19.7812 6.40625C19.8437 6.13542 19.9792 5.91667 20.1875 5.75C20.3958 5.58333 20.6458 5.5 20.9375 5.5C21.3333 5.5 21.6562 5.65625 21.9062 5.96875C22.1562 6.28125 22.2292 6.625 22.125 7L21.25 10.5H24.0312C24.4479 10.5 24.7812 10.6613 25.0312 10.9838C25.2812 11.3071 25.3542 11.6667 25.25 12.0625C25.1875 12.3542 25.0417 12.5833 24.8125 12.75C24.5833 12.9167 24.3229 13 24.0312 13H20.625L19.375 18H22.7812C23.1979 18 23.5312 18.1613 23.7812 18.4838C24.0312 18.8071 24.1042 19.1667 24 19.5625C23.9375 19.8542 23.7917 20.0833 23.5625 20.25C23.3333 20.4167 23.0729 20.5 22.7812 20.5H18.75L17.7187 24.5938C17.6562 24.8646 17.5208 25.0833 17.3125 25.25C17.1042 25.4167 16.8542 25.5 16.5625 25.5C16.1667 25.5 15.8437 25.3438 15.5937 25.0312C15.3437 24.7188 15.2708 24.375 15.375 24L16.25 20.5H11.25L10.2187 24.5938C10.1562 24.8646 10.0208 25.0833 9.8125 25.25C9.60416 25.4167 9.35416 25.5 9.0625 25.5ZM11.875 18H16.875L18.125 13H13.125L11.875 18Z"
          fill="black"
        />
      </g>
    </svg>
        {{ element.name }}
      </div>

      <div
        *ngIf="
          !element.uids.includes(currentUserId) &&
          element.id != 'wXzgNEb34DReQq3fEsAo7VTcXXNA'
        "
        class="channel"
      >
      <svg
      _ngcontent-ng-c3457599055=""
      fill="#000000"
      width="20px"
      height="20px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        _ngcontent-ng-c3457599055=""
        d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z"
      ></path>
    </svg>
        {{ element.name }}
      </div>
    </div>
  </div>
  <h1 class="fs-32 title-mobile">Benutzer:</h1>
  <div *ngFor="let element of allUserSorted; let i = index">
    <div class="userContainer cursor-pointer">
      <div class="user" (click)="setChannel(element.uid, 'user', i, $event)">
        <img src="{{ element.image }}" />{{ element.name }}
      </div>
    </div>
  </div>
</div>
