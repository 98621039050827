<div class="edit-profil-container" (click)="closeDialog()" [ngClass]="{'display-flex': hideOrShowSidebar.userProfilOpen, 
'display-none': !hideOrShowSidebar.userProfilOpen }">
    <div class="dialog" (click)="notCloseDialog($event)">
        <div class="header">
            <span class="header-title fs-24">Profil</span>
            <div class="close-button" (click)="closeDialog()"></div>
        </div>
        <div>
            <img class="profil-image" src={{this.hideOrShowSidebar.activeImage}}>
        </div>
        <div class="name-container">
            <div class="name-edit">
                @if(this.hideOrShowSidebar.activeUid == authService.currentUserSignal()?.uId){
                    <span class="user-name fs-32">{{ authService.currentUserSignal()?.name }}</span>
                }@else{
                    <span class="user-name fs-32">{{ this.hideOrShowSidebar.activeUser }}</span>
                }
            </div>
            <div class="online">
                @if(this.hideOrShowSidebar.activeUid == authService.currentUserSignal()?.uId || this.hideOrShowSidebar.onlineUserUidList.includes(this.hideOrShowSidebar.activeUid)){
                        <img class="online-icon" src="../../../assets/img/sidebar/Online.png" />
                        <span class="fs-20">Aktiv</span>
                      }@else {
                        <img class="online-icon" src="../../../assets/img/sidebar/Offline.png" />
                        <span class="fs-20">Abwesend</span>
                      }
            </div>
        </div>
        <div class="email-container">
            <div class="email-title-container">
                <img src="../../../assets/img/header/mail (1).png">
                <span class="fs-20 email-adress-title">E-Mail-Adresse</span>
            </div>
            <div class="email-adress-container">
                @if(this.hideOrShowSidebar.activeUid == authService.currentUserSignal()?.uId){
                    <span class="fs-18">{{ authService.currentUserSignal()?.email }}</span>
                }@else{
                    <span class="fs-18">{{ this.hideOrShowSidebar.activeEmail }}</span>
                }
            </div>
        </div>


        <div class="send-message-button" (click)="sendMessage()">
            <img src="../../../assets/img/header/mode_comment.png">
            <span class="fs-18 message">Nachricht</span>
        </div>


    </div>
</div>