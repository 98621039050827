@if(this.responsiveService.isSidebarOpen){
<div
  class="sidebar-container"
  [ngClass]="{
    'sidebar-container-open': !sidebarService.sidebarOpen,
    'sidebar-container-close': sidebarService.sidebarOpen
  }"
>
  <div class="header">
    <img src="../../../assets/img/sidebar/Workspace logo.png" />
    <span class="title">Devspace</span>
    <div class="add-new-message" (click)="addNewMessage()"></div>
  </div>
  <div class="menu">
    <app-search-field class="search-field"></app-search-field>
    <div class="channels-container">
      <div class="channels-title-container">
        <div
          (click)="activeteChannelTitle()"
          [ngClass]="{
            'channels-title': !activetedChannelTitle,
            'channels-title-active': activetedChannelTitle
          }"
        ></div>
        <div class="add-channel-button" (click)="addChannel()"></div>
      </div>
      @if(activetedChannelTitle){
      <div #scrollContainer class="scroll-container">
        <div
          class="channel-list"
          *ngFor="
            let channel of sidebarService.AllChannels.slice().reverse();
            let i = index
          "
        >
          <div
            class="channel"
            (click)="channelActive(i)"
            [ngClass]="{
              'channel-active': this.sidebarService.activeChannelIndex === i
            }"
          >
            @if
            (sidebarService.GlobalChannelUids[sidebarService.AllChannels.length
            - 1 - i].includes(authService.currentUserSignal()?.uId ?? '') ||
            (sidebarService.AllChannelsIds[sidebarService.AllChannels.length - 1
            - i] == 'wXzgNEb34DReQq3fEsAo7VTcXXNA')) {
            <div
              class="raute"
              [ngClass]="{
                'raute-active': this.sidebarService.activeChannelIndex === i
              }"
            ></div>
            } @else {
            <svg
              fill="#000000"
              width="20px"
              height="20px"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z"
              ></path>
            </svg>
            }
            <span
              class="channel-title fs-18"
              [ngClass]="{
                'channel-title-active':
                  this.sidebarService.activeChannelIndex === i
              }"
              class="channel-title fs-18"
              >{{ channel }}</span
            >
          </div>
        </div>
      </div>
      }
      <div class="add-new-channel" (click)="addChannel()">
        <div class="add-new-channel-button"></div>
        <span class="fs-18 add-channel-text">Channel hinzufügen</span>
      </div>
    </div>
    <div class="users-container">
      <div class="users-title-container" (click)="openUsersList()">
        <div
          [ngClass]="{
            'users-title': !usersTitleActive,
            'users-title-active': usersTitleActive
          }"
        ></div>
      </div>
    </div>
    <div
      [ngClass]="{
        'scroll-container-users': activetedChannelTitle,
        'scroll-container-users-max': !activetedChannelTitle
      }"
    >
      @if(usersTitleActive){
      <div>
        <div
          *ngFor="let user of sidebarService.AllUsers; let i = index"
          (click)="userActive(i)"
        >
          @if(authService.currentUserSignal()?.uId ==
          this.sidebarService.AllUids[i]){
          <div class="user-name-container">
            <img
              class="profil-image-user"
              src="{{ this.sidebarService.AllImages[i] }}"
            />
            <img
              class="online-icon"
              src="../../../assets/img/sidebar/Online.png"
            />
            <span
              class="fs-18 user-name"
              [ngClass]="{
                'user-active': this.sidebarService.activeUserIndex === i
              }"
              >{{ user }}</span
            >
            <span
              class="fs-18 user-name-me"
              [ngClass]="{
                'user-active': this.sidebarService.activeUserIndex === i
              }"
              >(Du)</span
            >
          </div>
          }
        </div>
      </div>
      <div>
        <div
          *ngFor="let user of sidebarService.AllUsers; let i = index"
          (click)="userActive(i)"
        >
          @if(authService.currentUserSignal()?.uId !==
          this.sidebarService.AllUids[i]){
          <div class="user-list">
            <img
              class="profil-image"
              src="{{ this.sidebarService.AllImages[i] }}"
            />
            @if
            (this.sidebarService.onlineUserUidList.includes(this.sidebarService.AllUids[i])){
            <img
              class="online-icon"
              src="../../../assets/img/sidebar/Online.png"
            />
            } @else {
            <img
              class="online-icon"
              src="../../../assets/img/sidebar/Offline.png"
            />
            }

            <span
              class="fs-18 user-name"
              [ngClass]="{
                'user-active': this.sidebarService.activeUserIndex === i
              }"
              >{{ user }}</span
            >
          </div>
          }
        </div>
      </div>
      }
    </div>
  </div>
  <div class="add-new-message-responsive" (click)="addNewMessage()"></div>
</div>
}
