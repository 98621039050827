<section>
    <img class="logo" src="../../../assets/img/landing-page/DABubble-logo.png" alt="daBubble-logo">

    <div class="impressum-section">

        <a (click)="goBack()"><img class="arrow-back cursor-pointer" [src]="imgSrcArrow"
            (mouseover)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back-hover.png'"
            (mouseout)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back.png'" alt="arrow-back"></a>

        <span>
            <h1 class="fs-46">Impressum</h1>
            <p class="fs-18">Mark Hegedüs, Joost Heidrich, Gábor Holzleiter</p>
            <p class="fs-18">Schützenstrasse 27.</p>
            <p class="fs-18">8570 Weinfelden</p>
        </span>
        
        <span>
            <h1 class="fs-46">Contact:</h1>
            <p class="fs-18">Email: mail&#64;mark.hegedus.com</p>
        </span>
    </div>
</section>