<section>

    <img class="logo" src="../../../assets/img/landing-page/DABubble-logo.png" alt="daBubble-logo">

    <form [formGroup]="form" class="reset-password-section" (ngSubmit)="onSubmit()">
        <a (click)="goBack()"><img class="arrow-back cursor-pointer" [src]="imgSrcArrow"
                (mouseover)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back-hover.png'"
                (mouseout)="imgSrcArrow = '../../../assets/img/landing-page/arrow-back.png'" alt="arrow-back"></a>

        <h1 class="fs-46">Passwort zurücksetzen</h1>

        <p style="max-width: 486px; text-align: center;" class="fs-20">Bitte geben Sie Ihre E-Mail-Adresse ein.</p>

        <div class="input-container" style="position: relative;">
            <img class="input-icon" src="../../../assets/img/landing-page/mail.png" alt="mail">
            <input formControlName="email" required class="input-design" placeholder="beispielname@email.com"
                type="email">
            <span *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)"
                class="error-message">
                *Diese E-Mail-Adresse ist leider ungültig.
            </span>
            <span *ngIf="errorMessage" class="error-message">
                {{errorMessage}}
            </span>
        </div>

        <p class="fs-20">Wir senden Ihnen eine E-Mail,
            über die
            Sie Ihr Passwort ändern können.</p>

        <span class="btn-container">
            <button [ngClass]="{'blue-btn': form.valid, 'disabled-btn fs-18': !form.valid}" type="submit"
                [disabled]="!form.valid">
                E-Mail senden
            </button>
        </span>
    </form>

    <div class="legal-section">
        <a class="fs-18 purp-link" href="impressum">Impressum</a>
        <a class="fs-18 purp-link" href="privacy">Datenschutz</a>
    </div>

    <div class="message-container" [ngClass]="{'bg-white': emailSent}">
        <div class="email-sent-message" [ngClass]="{'animation-class': emailSent}">
            <img src="../../../assets/img/landing-page/send.png" alt="send">
            <p class="fs-36">E-Mail gesendet</p>
        </div>
    </div>
</section>

